function getPardotUrl() {
    return ("https:" == document.location.protocol ? "https://" : "http://") + "pi.pardot.com/analytics?ver=3";
}

function sendToPardot(url, title) {
    var pi = window.pi;
    var prop;
    var endpoint = getPardotUrl();
    var data = {
        url: url,
        title: title,
        referrer: pi.tracker.url
    };
    var newScript = document.createElement("script");
    var headID = document.getElementsByTagName("head")[0];
    var referenceScript = headID.getElementsByTagName("script")[0];

    // flesh out data object
    for (prop in pi.tracker) {
        if (pi.tracker.hasOwnProperty(prop) && !data.hasOwnProperty(prop)) data[prop] = pi.tracker[prop];
    }

    // build out enpoint
    for (prop in data) {
        if (data.hasOwnProperty(prop)) endpoint += "&" + prop + "=" + encodeURIComponent(data[prop]);
    }

    // prep script element
    newScript.type = "text/javascript";
    newScript.src = endpoint;

    // initiate call to enpoint by inserting in head, before other script tags if they exist
    referenceScript ? headID.insertBefore(newScript, referenceScript) : headID.appendChild(newScript);
}

function init() {
    var asset;
    var assetsOfInterest = document.getElementsByClassName("myPardotTrackClick");

    if (assetsOfInterest.length > 0) {
        var onClick = function(event) {
            var target = event.currentTarget ? event.currentTarget : event.srcElement;

            if (target) {
                var uri = target.getAttribute("href");
                if (uri) {
                    var url = window.origin + uri;
                    var title = target.getAttribute("title");
                    sendToPardot(url, title);
                } else {
                    return event;
                }
            }
        };

        for (var i = 0; i < assetsOfInterest.length; i++) {
            (asset = assetsOfInterest[i]).addEventListener ? asset.addEventListener("click", onClick, false) : asset.attachEvent && asset.attachEvent("onclick", onClick);
        }
    }
}

export default { init };
