function seekCategories() {
    let categorized = document.querySelector("meta[name='categories']");

    if (categorized) {
        let pageCategories = categorized.getAttribute("content");
        trackCategories(pageCategories);
    }
}

function trackCategories(pageCategories) {
    if (pageCategories) {
        var dataLayer = window.dataLayer;
        dataLayer.push({
            event: "categorizedPage",
            pageCategories: pageCategories
        });
    }
}

function init() {
    seekCategories();
}

export default { init };
