import { removeNode } from "./util";

function toggleNav() {
    const jabilNav = document.querySelector(".nav");
    const mobileOpen = document.querySelector(".nav__mobileBtn");
    const mobileClose = document.querySelector(".nav__closeBtn");
    const mobileOverlay = document.querySelector(".nav__closeOverlay");

    if (mobileOpen) {
        mobileOpen.addEventListener("click", function() {
            if (!jabilNav.classList.contains("nav--active")) {
                jabilNav.classList.add("nav--active");
                toggleNavLinksVisibility();
            }
            mobileClose.focus();
        });
    }

    if (mobileClose) {
        mobileClose.addEventListener("click", function() {
            if (jabilNav.classList.contains("nav--active")) {
                jabilNav.classList.remove("nav--active");
                toggleNavLinksVisibility(false);
            }
            mobileOpen.focus();
        });
    }
    if (mobileOverlay) {
        mobileOverlay.addEventListener("click", function() {
            if (jabilNav.classList.contains("nav--active")) {
                jabilNav.classList.remove("nav--active");
                toggleNavLinksVisibility(false);
            }
            mobileOpen.focus();
        });
    }
}

function toggleNavLinksVisibility(show = true) {
    const navMobileOverlay = document.querySelector(".nav__mobileOverlay");
    if (show) {
        navMobileOverlay.classList.remove("hidden");
    } else {
        navMobileOverlay.classList.add("hidden");
    }
}

function toggleSubNav() {
    const mobileOverlay = document.querySelector(".nav__mobileOverlay");
    if (mobileOverlay) {
        const mobileNavLinkTriggers = mobileOverlay.querySelectorAll(".navLink__trigger");

        if (mobileNavLinkTriggers[0]) {
            for (let i = 0; i < mobileNavLinkTriggers.length; i++) {
                let navLinkTrigger = mobileNavLinkTriggers[i];
                const openSub = () => {
                    let parentElement = navLinkTrigger.parentElement;
                    let content = parentElement.querySelector(".navLink__subnav");
                    let isActive = parentElement.classList.toggle("navLink__item--active");
                    content.style.display = isActive ? "block" : "none";
                    parentElement.style.height = isActive ? parentElement.scrollHeight + "px" : "auto";

                    setTimeout(() => {
                        if (content.querySelector("a.ulClassListArea__title")) content.querySelector(".ulClassListArea__title").focus();
                        else content.querySelectorAll(".subNavLink__title")[0].focus();
                    }, 1e3);
                };

                navLinkTrigger.addEventListener("click", function() {
                    openSub();
                });

                navLinkTrigger.addEventListener("focus", function() {
                    this.click();
                });
            }
        }
    }
}

function toggleLangNav() {
    const mobileOverlay = document.querySelector(".nav__mobileOverlay");
    if (mobileOverlay) {
        const navGlobe = mobileOverlay.querySelector(".nav__globe");
        if (navGlobe) {
            navGlobe.addEventListener("click", function() {
                let langSubNav = mobileOverlay.querySelector(".navLangDropdown__mobile");
                navGlobe.classList.toggle("navLink__item--active");
                langSubNav.classList.toggle("navLangDropdownMobile--open");
            });

            navGlobe.addEventListener("focus", function() {
                this.click();
            });
        }
    }
}

function openSearch(config) {
    config.navRight.classList.add("nav__search--active");
    config.navSearch.classList.add("nav__search--active");
    config.navRight.classList.add("nav__right--relative");
    config.closeIcon.classList.add("nav__closeIcon--active");
    config.searchIcon.style.visibility = "hidden";
    config.navGlobe.style.visibility = "hidden";
    config.navStock.style.visibility = "hidden";

    setTimeout(function() {
        config.navSearch.querySelector("input").focus();
    }, 200);
}
function closeSearch(config) {
    config.navRight.classList.remove("nav__search--active");
    config.navSearch.classList.remove("nav__search--active");
    config.closeIcon.classList.remove("nav__closeIcon--active");
    config.searchIcon.style.visibility = "visible";
    config.navGlobe.style.visibility = "visible";
    config.navStock.style.visibility = "visible";

    setTimeout(function() {
        config.navRight.classList.remove("nav__right--relative");
    }, 300);
}

function toggleSearch() {
    let config = {};

    config.searchIcon = document.querySelector(".nav__searchIcon");
    config.closeIcon = document.querySelector(".nav__closeIcon");
    config.navSearch = document.querySelector(".nav__search");
    config.navRight = document.querySelector(".nav__right");
    config.navGlobe = document.querySelector(".nav__globe");
    config.navSearchInput = document.querySelectorAll(".nav__searchInput");

    config.navStock = document.querySelector(".nav__stock");
    if (config.searchIcon) {
        config.searchIcon.addEventListener("click", function(e) {
            e.preventDefault();
            openSearch(config);
        });

        config.closeIcon.addEventListener("click", function(e) {
            e.preventDefault();
            closeSearch(config);
        });
    }
    // Prepend search input in navLink UL in mobile
    var mobileOverlay = document.querySelector(".nav__mobileOverlay");

    if (mobileOverlay) {
        var overlayNavSearch = mobileOverlay.querySelector(".nav__search");
        var hasMobileSearchEl = document.querySelector(".mobile-search");

        if (overlayNavSearch && !hasMobileSearchEl) {
            mobileOverlay.querySelector(".nav__search").classList.add("nav__search--active");
            var navUL = mobileOverlay.querySelector(".navLink");
            var activeSearchInput = mobileOverlay.querySelector(".nav__search");
            var searchMobileLi = document.createElement("li");
            searchMobileLi.classList.add("mobile-search");
            searchMobileLi.innerHTML = activeSearchInput.outerHTML;
            navUL.insertBefore(searchMobileLi, navUL.querySelector("li:first-child"));
            removeNode(document.querySelector(".nav__mobileOverlay > .nav__search"));
        }

        if (config.navSearchInput) {
            config.navSearchInput.forEach(function(search) {
                search.addEventListener("keyup", function(e) {
                    if (e.keyCode == 27) {
                        closeSearch(config);
                    }
                });
            });
        }
    }
}

function prepareStuckNav(isStuck) {
    const jabilNav = document.querySelector(".nav");
    if (isStuck) {
        jabilNav.classList.remove("nav--homeStuck");
        jabilNav.classList.remove("nav--homeHasAnim");
    } else {
        jabilNav.classList.add("nav--homeStuck");

        setTimeout(function() {
            jabilNav.classList.add("nav--homeHasAnim");
        }, 100);
    }
}

function toggleStuckNav(isStuck) {
    const jabilNav = document.querySelector(".nav");
    if (isStuck) {
        jabilNav.classList.remove("nav--homeStuckOpen");
    } else {
        jabilNav.classList.add("nav--homeStuckOpen");
    }
}

function setSubnavLinksEvents() {
    const links = document.querySelectorAll(".navLink__item");

    if (links) {
        links.forEach(link => {
            const triggerIcons = link.querySelectorAll(".navLink__trigger");
            triggerIcons.forEach(triggerIcon => {
                const setClass = () => {
                    if (triggerIcon.classList.contains("navLink__trigger--expand")) {
                        link.querySelector(".navLink__trigger--collapse").focus();
                    } else if (triggerIcon.classList.contains("navLink__trigger--collapse")) {
                        link.querySelector(".navLink__trigger--expand").focus();
                    }
                };

                triggerIcon.addEventListener("click", function() {
                    setTimeout(() => setClass, 1e3);
                });
            });
        });
    }
}

function scrollHeader() {
    let header = document.querySelector(".nav");

    if (header) {
        let scrollValue = pageYOffset;
        if (scrollValue > 30) {
            header.classList.add("scrolled");
        } else {
            header.classList.remove("scrolled");
        }

        window.addEventListener("scroll", function() {
            scrollValue = pageYOffset;

            if (scrollValue > 30) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }
        });
    }
}

function init() {
    toggleNav();
    toggleSubNav();
    toggleSearch();
    toggleLangNav();
    setSubnavLinksEvents();
    scrollHeader();
}

export default { init, prepareStuckNav, toggleStuckNav, scrollHeader };
