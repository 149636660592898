import whatInput from "what-input";

let mediaQuery = window.innerWidth;

function checkFlag(ev, el) {
    if (!el.classList.contains("is-open")) {
        ev.preventDefault();
        el.classList.toggle("is-open");
    }
}

function doubleTapOpen() {
    if (mediaQuery >= 1024) {
        window.addEventListener("pointerup", function(event) {
            if (whatInput.ask() === "touch") {
                if (event.target.classList.contains("navLink__title--hasSubnav")) {
                    let navItem = event.target.parentElement;
                    let subnav = navItem.lastElementChild;

                    event.target.addEventListener("click", function(ev) {
                        checkFlag(ev, event.target);
                        event.target.focus();
                        subnav.style.display = "block";
                        subnav.style.opacity = "1";
                    });
                    /*
                    when user clicks off nav item then remove class,
                    so user can click to see subnav again w/out redirect
                    */
                    event.target.addEventListener("blur", function(ev) {
                        checkFlag(ev, event.target);
                        event.target.classList.remove("is-open");
                        subnav.removeAttribute("style");
                    });
                }
            }
        });

        //ios
        window.addEventListener("touchstart", function(event) {
            if (event.target.classList.contains("navLink__title--hasSubnav")) {
                event.target.addEventListener("click", function(ev) {
                    checkFlag(ev, event.target);
                });
            }
        });
    }
}

function init() {
    doubleTapOpen();
}

export default { init };
