import { iframeResize } from "iframe-resizer";
import { pushEvent } from "./track";
import postMaster from "./postMaster";

function pardotIframe() {
    let pardotIframes = document.getElementsByClassName("pardotForm--iframe");
    if (pardotIframes[0]) {
        for (let i = 0; i < pardotIframes.length; i++) {
            let pardotForm = pardotIframes[i];
            let iframe = document.createElement("iframe");
            let formWrap = pardotForm.getElementsByClassName("pardotForm__wrap")[0];
            let src = formWrap.getAttribute("data-src");
            let params = window.location.search;
            formWrap.appendChild(iframe);
            iframe.setAttribute("src", src + params);

            let spinner = pardotForm.getElementsByClassName("pardotForm__spinner")[0];
            initSpinner(formWrap, spinner);

            let id = formWrap.getAttribute("data-form-id");
            iframe.setAttribute("id", id);
            iframe.setAttribute("width", "100%");
            iframe.setAttribute("type", "text/html");
            iframe.setAttribute("frameborder", 0);
            iframe.setAttribute("scrolling", "no");
            iframe.setAttribute("seamless", "seamless");
            iframe.setAttribute("allowTransparency", "true");
            iframe.style.overflow = "hidden";
            iframe.style.border = 0;
            iframe.style.height = 0;

            iframe.onload = function() {
                postMaster.init();
                iframeResize({}, `#${id}`);
            };
        }
    }
}

function initSpinner(formWrap, spinner) {
    if (spinner) {
        spinner.addEventListener(
            "animationend",
            ev => {
                if (ev.type === "animationend") {
                    spinner.style.display = "none";
                    formWrap.classList.add("pardotForm__wrap--fadeIn");
                }
            },
            false
        );
        spinner.classList.add("pardotForm__spinner--fadeOut");
    } else {
        formWrap.classList.add("pardotForm__wrap--fadeIn");
    }

    // Fadeout the spinner after 5s
    setTimeout(function() {
        spinner.classList.add("pardotForm__spinner--fadeOut");
    }, 5000);
}

function subscriptionForm() {
    let subscriptions = document.getElementsByClassName("pardotForm--subscription");
    if (subscriptions[0]) {
        for (let i = 0; i < subscriptions.length; i++) {
            let subscription = subscriptions[i];
            let formWrap = subscription.getElementsByClassName("pardotForm__wrap")[0];
            let spinner = subscription.getElementsByClassName("pardotForm__spinner")[0];
            let form = formWrap.getElementsByClassName("pardotForm__form")[0];
            let email = form.getElementsByClassName("pardotForm__email")[0];
            // Trace when animation end
            spinner.addEventListener(
                "animationend",
                ev => {
                    if (ev.type === "animationend") {
                        spinner.style.display = "none";
                        form.classList.add("pardorForm__form--visible");
                        formWrap.classList.add("pardotForm__wrap--fadeIn");
                        subscription.classList.add(formWrap.getAttribute("data-background"));
                    }
                },
                false
            );
            // Fadeout the spinner after 350ms
            setTimeout(function() {
                spinner.classList.add("pardotForm__spinner--fadeOut");
            }, 350);
            // validate Email
            email.addEventListener("blur", validateEmail);

            // on submit form
            form.addEventListener("submit", submitSubscriptionForm);
        }
    }
}

function validateEmail(event) {
    let blocked_domains = ["flextronics.com", "flex.com", "aol.com", "gmail.com", "yahoo.com", "outlook.com", "icloud.com", "mail.com", "hotmail.com", "plexus.com", "thclips.com", "mybizexplained.com", "customlogostuff.com", "explainmybusiness.com", "emailcheckall.com", "sanmina.com", "foxconn.com", "celestica.com", "bench.com", "plexus.com", "aptar.com", "essentium3d.com"];
    let theEmailValue = event.target.value;
    let idx = theEmailValue.indexOf("@");

    if (idx > -1) {
        let splitStr = theEmailValue.split("@");

        if (blocked_domains.indexOf(splitStr[1]) > -1) {
            event.target.classList.add("error");
            event.target.value = "";
            event.target.placeholder = "Enter a valid business address.";
        } else {
            event.target.classList.remove("error");
            event.target.placeholder = "";
        }
    }
}

function submitSubscriptionForm(event) {
    if (event.target.classList.contains("pardotForm__form--submitted")) {
        event.preventDefault();
    }
    event.target.classList.add("pardotForm__form--submitted");

    pushEvent("blog-subscribe-success");
}

function init() {
    pardotIframe();
    subscriptionForm();
}

export default { init };
