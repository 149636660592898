import PhotoSwipe from "photoswipe/dist/photoswipe.min.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js";

function initSwipeGallery() {
    var images = [];
    var bodyObj = document.body;
    var imageItems;
    imageItems = document.querySelectorAll(".imageZoom");

    var gallery = document.querySelectorAll(".gallery");
    for (var i = 0; i < gallery.length; i++) {
        if (gallery[i].hasAttribute("data-gallery-name")) {
            imageItems = document.querySelectorAll(".galleryImage");
        }
    }

    imageItems.forEach(e => {
        var item = {
            el: e,
            src: e.getAttribute("data-src"),
            w: e.getAttribute("data-width") * 1.5,
            h: e.getAttribute("data-height") * 1.5,
            title: e.getAttribute("data-caption")
        };
        images.push(item);
    });

    function getThumbBounds(index) {
        var thumbnail = imageItems[index];
        var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        var rect = thumbnail.getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
    }

    function openGallery() {
        Array.from(imageItems).forEach(function(e, index) {
            e.addEventListener("click", function() {
                var pswpElement = document.querySelectorAll(".pswp")[0];
                var options = {
                    index: index,
                    shareEl: false,
                    galleryPIDs: true,
                    captionEl: true,
                    counterEl: true,
                    getThumbBoundsFn: getThumbBounds
                };

                //push nav & footer back on open
                bodyObj.classList.add("body--pushToBack");
                bodyObj.classList.add("photoswipe");

                // Initializes and opens PhotoSwipe
                var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, images, options);

                //push nav & footer forward on close
                gallery.listen("destroy", function() {
                    bodyObj.classList.remove("body--pushToBack");
                    bodyObj.classList.remove("photoswipe");
                });

                gallery.init();
            });
        });
    }

    openGallery();
}

function init() {
    initSwipeGallery();
}

export default { init };
