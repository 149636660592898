function init() {
    const pillarsSection = document.querySelector(".pillarsSection");

    if (pillarsSection) {
        const pillarLinks = pillarsSection.querySelectorAll(".pillarSection__links .pillarSection__link");
        if (pillarLinks) {
            initPillarsClickEvents();
            window.addEventListener("scroll", setPillarsScrollEvents);
            window.addEventListener("resize", setPillarsScrollEvents);
        }
    }
}

function initPillarsClickEvents() {
    const pillarsSection = document.querySelector(".pillarsSection");
    const pillarLinks = pillarsSection.querySelectorAll(".pillarSection__links .pillarSection__link");

    pillarLinks.forEach(function(link) {
        function setPillarActiveClass() {
            const pillarItems = pillarsSection.querySelectorAll(".pillarSection__details .pillar__item");
            const uuid = link.dataset.id;
            pillarItems.forEach(function(pillar) {
                if (pillar.dataset.id === uuid) {
                    pillar.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            });
        }

        link.addEventListener("click", setPillarActiveClass);
    });
}

function setPillarsScrollEvents() {
    const container = document.querySelector(".pillarsSection");
    const pillarLinks = container.querySelectorAll(".pillarSection__links .pillarSection__link");
    const pillarItems = container.querySelectorAll(".pillarSection__details .pillar__item");

    pillarItems.forEach(function(item, index) {
        if (isAtCenter(item)) {
            pillarLinks.forEach(function(link) {
                link.classList.remove("js-active");
            });
            pillarLinks[index].classList.add("js-active");
        }
    });
}

const getElementPosition = function(elem) {
    const position = elem.getBoundingClientRect();
    const navOffsetHeight = 120;
    return position.height / 2 + position.top - navOffsetHeight;
};

const isAtCenter = function(elem) {
    const windowHeight = window.innerHeight / 2;
    return windowHeight > getElementPosition(elem);
};

export default { init };
