function init() {
    const skipLinks = document.querySelectorAll(".skipLinks .skipLinks__link");

    if (skipLinks) {
        skipLinks.forEach(function(skipLink) {
            if (skipLink) {
                skipLink.addEventListener("keydown", function(e) {
                    if (e.keyCode === 32) {
                        e.preventDefault();
                        skipLink.click();
                    }
                });
            }
        });
    }
}

export default { init };
