import "core-js/modules/es.promise";
import smoothscroll from "smoothscroll-polyfill";
import whenDomReady from "when-dom-ready";
import siteConfig from "../modules/siteConfig";
import extend from "../modules/extend";
import nav from "../modules/nav";
import video from "../modules/video";
import pardotForm from "../modules/pardotForm";
import doubleTapOpen from "../modules/doubleTap";
import photoSwipe from "../modules/photoSwipe";
import Rellax from "rellax";
import debounce from "debounce";
import lozad from "lozad";
import links from "../modules/links";
import assetTracker from "../modules/assetTracker.js";
import categoryTracker from "../modules/categoryTracker.js";
import accessibility from "../modules/accessibility.js";
import pillarsSection from "../modules/pillarsSection";

class BasePage {
    constructor(...opts) {
        extend(this, opts);
        this.init();
        this.minWidthRellax = 1024;
    }

    init() {
        var self = this;
        self.ready = false;
        self.onBeforeReady();

        whenDomReady().then(function() {
            self.onReady();
        });

        window.addEventListener("load", function() {
            self.onLoad();
        });

        window.onresize = debounce(function() {
            if (self.needsRellaxUpdate()) {
                self.initRellax();
            }
        }, 200);

        const el = document.querySelectorAll(".lazyload");
        const observer = lozad(el); // passing a `NodeList` (e.g. `document.querySelectorAll()`) is also valid
        observer.observe();
    }

    onBeforeReady() {
        extend(this, siteConfig);
    }

    onReady() {
        var self = this;
        self.ready = true;
        accessibility.init();
        nav.init();
        video.init();
        pardotForm.init();
        self.mobileRellaxElements = Array.from(document.querySelectorAll(".rellax--disable-mobile"));
        self.initRellax();
        links.init();
        smoothscroll.polyfill();
        document.getElementsByTagName("body")[0].classList.add("is-ready");
        photoSwipe.init();
        doubleTapOpen.init();
        assetTracker.init();
        categoryTracker.init();
        pillarsSection.init();
    }

    onLoad() {
        document.getElementsByTagName("body")[0].classList.add("loaded");
    }

    isReady() {
        var self = this;
        return self.ready === true;
    }

    initRellax() {
        /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
        if (document.querySelectorAll(".rellax").length > 0) {
            if (this.rellax) {
                try {
                    this.rellax.destroy();
                } catch (e) {}
            }

            if (!this.editMode && this.mobileRellaxElements) {
                if (window.innerWidth <= this.minWidthRellax) {
                    this.mobileRellaxElements.forEach(el => el.classList.remove("rellax"));
                    this.rellax = new Rellax(".rellax");
                } else {
                    this.mobileRellaxElements.forEach(el => el.classList.add("rellax"));
                    this.rellax = new Rellax(".rellax");
                }
            } else {
                this.rellax = new Rellax(".rellax");
            }
        }
    }

    needsRellaxUpdate() {
        const hasRellaxClass = this.mobileRellaxElements && this.mobileRellaxElements.some(el => el.classList.contains("rellax"));
        if (window.innerWidth <= this.minWidthRellax && hasRellaxClass) return true;
        if (window.innerWidth > this.minWidthRellax && !hasRellaxClass) return true;
        return false;
    }

    noop() {}
}

export default BasePage;
