function sendMessages() {
    // Collect and send any form qualifiers
    let qualifiedPardotIframes = document.getElementsByClassName("pardotForm--qualified");
    //here
    for (let i = 0; i < qualifiedPardotIframes.length; i++) {
        let qualifier = qualifiedPardotIframes[i].dataset.qualifier;
        let iframe = qualifiedPardotIframes[i].getElementsByTagName("iframe")[0];
        let iframeWindow = iframe.contentWindow || iframe;
        // post message
        iframeWindow.postMessage(qualifier, "https://go.jabil.com");
    }
}

function receiveMessages() {
    var recognizedOrigins = ["https://go.jabil.com"];

    function processMessage(evt) {
        if (recognizedOrigins.includes(evt.origin)) {
            var data = evt.data || "";

            if (typeof data == "string" && data.includes("{") && data.includes("}")) {
                var json = JSON.parse(data);

                // receive submitted form ID
                if (json.submission) {
                    var dataLayer = window.dataLayer;
                    dataLayer.push({
                        event: "pardot-submission",
                        formId: json.submission
                    });
                }

                // receive and store Pardot form campaign id
                if (json.piCId) {
                    var pi = window.pi;
                    var piCId = json.piCId;

                    // override default campaign id with one set by form while user is on current page
                    if (window.piCId) {
                        window.piCId = piCId;
                    }

                    if (pi && pi.tracker && typeof pi.tracker == "object") {
                        pi.tracker.campaign_id = piCId;
                    }
                }
            }
        }
    }

    if (window.addEventListener) {
        // For standards-compliant web browsers
        window.addEventListener("message", processMessage, false);
    } else {
        window.attachEvent("onmessage", processMessage);
    }
}

function init() {
    sendMessages();
    receiveMessages();
}

export default { init };
