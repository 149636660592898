let vidyardScriptLoaded = false;
let apiLoaded = false;

const getVideoId = (element, idAttribute) => element.getAttribute(idAttribute) || "";

const videoIsInline = element => element.classList.contains("video--inlineEmbed");

const isVidyardIframe = (el, videoId) => el instanceof HTMLIFrameElement && el.classList && el.classList.contains(`vidyard-iframe-${videoId}`);

const appendOverlay = videoType => {
    var loaderOverlay = document.createElement("div");
    loaderOverlay.style.cssText = "background-image: rgba(0,0,0,.58) linear-gradient(90deg,rgba(7,35,62,.9),rgba(0,48,85,.9)) repeat scroll 0 0!important; height: 100vh; left: 0px; opacity: 1; position: fixed; top: 0px; width: 100vw; z-index: 1000; background-color: rgba(0, 0, 0, 0.5); display: flex; align-items: center;";
    loaderOverlay.className = "popover__overlay popover__overlay--" + videoType;
    loaderOverlay.innerHTML = '<div class="spinner"></div>';
    document.getElementsByTagName("body")[0].appendChild(loaderOverlay);
};

const loadVidyardScript = (cb = () => {}, ...args) => {
    appendScript("https://play.vidyard.com/embed/v4.js", function() {
        vidyardScriptLoaded = true;
        cb.apply(this, args);
    });
};

const awaitVidyardAPI = (cb = () => {}, ...args) => {
    var interval = window.setInterval(checkStatus, 100);

    function checkStatus() {
        if (window.VidyardV4) {
            clearInterval(interval);
            apiLoaded = true;
            cb.apply(this, args);
        }
    }
};

const playLightbox = videoId => {
    var player = window.VidyardV4.api.getPlayersByUUID(videoId)[0];
    if (player) {
        player.showLightbox();
        window.VidyardV4.api.addReadyListener(() => {
            let overlay = document.querySelectorAll(".popover__overlay--vidyard")[0];
            if (overlay) document.getElementsByTagName("body")[0].removeChild(overlay);
        }, videoId);
    } else {
        // keep trying until this specific player loads
        setTimeout(playLightbox, 100, videoId);
    }
};

const prepareInline = (videoId, videoCover) => {
    // eslint-disable-next-line
    window.VidyardV4.api.addReadyListener((_, player) => {
        player.on("play", function() {
            if (videoCover.classList) videoCover.classList.add("video__videoCover--hidden");
            player._callbackStore.play = []; /* runs first time only */
        });
    }, videoId);
};

function vidyard() {
    let vidyardVideos = document.querySelectorAll(".video--vidyard");
    let vidyardButtons = document.querySelectorAll(".vidyard__videoButton");

    if (vidyardVideos.length > 0 || vidyardButtons.length > 0) {
        vidyardVideos.forEach(function(element) {
            setBackground(element);
            let videoEmbed = element.querySelector(".video__embed") || "";
            let videoId = getVideoId(videoEmbed, "data-uuid");

            if (videoIsInline(element)) {
                if (!vidyardScriptLoaded) loadVidyardScript();

                let videoCover = element.querySelector(".video__videoCover") || "";

                if (element.classList && element.classList.contains("video--isProtected")) {
                    if (videoCover.classList) {
                        window.focus();
                        window.addEventListener("blur", function() {
                            window.setTimeout(function() {
                                if (isVidyardIframe(document.activeElement, videoId)) {
                                    videoCover.classList.add("video__videoCover--hidden");
                                }
                            }, 0);
                        });
                    }
                } else {
                    apiLoaded ? prepareInline(videoId, videoCover) : awaitVidyardAPI(prepareInline, videoId, videoCover);
                }
            } else {
                element.addEventListener("click", function() {
                    appendOverlay("vidyard");
                    if (!vidyardScriptLoaded) loadVidyardScript();
                    apiLoaded ? playLightbox(videoId) : awaitVidyardAPI(playLightbox, videoId);
                });
            }
        });

        vidyardButtons.forEach(e => {
            let videoId = getVideoId(e, "data-uuid");
            let buttonMarkup = document.createElement("div");
            buttonMarkup.setAttribute("class", "video__embed vidyard-player-embed");
            buttonMarkup.setAttribute("data-uuid", videoId);
            buttonMarkup.setAttribute("data-v", "4");
            buttonMarkup.setAttribute("data-type", "lightbox");

            //create independent video element for vidyard to append vidyard-player-container to
            e.parentElement.insertBefore(buttonMarkup, e.previousElementSibling);

            e.addEventListener("click", function() {
                appendOverlay("vidyard");
                if (!vidyardScriptLoaded) loadVidyardScript();
                apiLoaded ? playLightbox(videoId) : awaitVidyardAPI(playLightbox, videoId);
            });
        });
    }
}

function setBackground(elememt) {
    let coverSrc;
    let dataImg;
    let coverImageEl = elememt.querySelector(".videoCover__img");
    if (coverImageEl) dataImg = coverImageEl.getAttribute("data-img");
    if (dataImg == "false") {
        let videoEmbed = elememt.querySelector(".video__embed");
        if (videoEmbed) coverSrc = videoEmbed.getAttribute("src");
        let bgStyle = "background-image: url('" + coverSrc + "')";
        coverImageEl.setAttribute("style", bgStyle);
    } else {
        if (window.innerWidth < 900) {
            const mobileImg = coverImageEl.dataset.mobile;
            if (mobileImg) coverImageEl.dataset.backgroundImage = mobileImg;
        }
    }
}

function appendScript(src, onload = () => {}) {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = function() {
        script.onload = null;
        onload();
    };
    document.getElementsByTagName("head")[0].appendChild(script);
    script.src = src;
}

function init() {
    vidyard();
}

export default { init };
