const groomExternalLinks = function() {
    var externalLinks = document.querySelectorAll("a[href^=http]");
    if (externalLinks.length > 0) {
        let host = window.location.host;
        for (var i = 0; i < externalLinks.length; i++) {
            var link = externalLinks[i];
            if (link.href.indexOf(host) == -1) {
                link.setAttribute("target", "_blank");
            }
        }
    }
};

const groomAssetLinks = function() {
    let assetLinks = document.querySelectorAll(`a[href^='/dam/jcr:'], a[href^='${window.location.origin}/dam/jcr:'], a[href^='/local/dam/jcr:']`);
    if (assetLinks.length > 0) {
        for (var i = 0; i < assetLinks.length; i++) {
            var link = assetLinks[i];
            link.setAttribute("target", "_blank");
            link.classList.add("myPardotTrackClick");
        }
    }
};

function init() {
    groomExternalLinks();
    groomAssetLinks();
}

export default { init };
