window.dataLayer = window.dataLayer || [];

// Push to the dataLayer, with a failsafe
export const pushTo = obj => {
    if (window.dataLayer) {
        window.dataLayer.push(obj);
    }
};

// Push GTM custom events into dataLayer
export const pushEvent = event => pushTo({ event: event });

// Push google analytics events into dataLayer
export const trackEvent = obj =>
    pushTo(
        Object.assign(
            {
                event: "GAevent",
                eventCategory: "",
                eventAction: "",
                eventLabel: "",
                eventNonInteraction: false,
                eventValue: ""
            },
            obj
        )
    );
